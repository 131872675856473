<template>
    <div class="nvx-app-container">
        <nvx-button-view>

            <template v-slot:content>
                <router-link to="/account">
                    <chevron :iconDirection="'left'" />
                </router-link>

                <h2 class="sdc-title"> {{ $t("email_update_title") }} </h2>

                <nvx-list>
                    <nvx-section>
                        <p> {{ $t("email_current") | capitalize }} </p>
                        <div class="sdc-field non-modifiable"> {{ getUserName }} </div>
                    </nvx-section>

                    <nvx-section>
                        <p> {{ $t("email_update_new") | capitalize }} </p>
                        <input v-model="newEmail" @input="checkNewEmail" type="text" class="sdc-field" inputmode="email"
                            autocapitalize="off" placeholder="johndoe@gmail.com"
                            :class="{ 'invalid': isNewEmailInvalid }" />
                        <small> {{ $t("email_update_information") }} </small>
                        <small v-if="isNewEmailInvalid" class="invalid-text">
                            {{ $t("incorrect_email") | capitalize }}
                        </small>
                    </nvx-section>

                    <nvx-section>
                        <p> {{ $t("check_password") | capitalize }} </p>
                        <div class="sdc-field sdc-password-field">
                            <input ref="passwordInput" v-model="password" @input="checkPassword" :type="showPassword ? 'text' : 'password'"
                                class="sdc-password-input" id="inputPassword" :placeholder="$t('login_password')"
                                :class="{ 'invalid': isPasswordInvalid }" />
                            <eyes :open=!showPassword @click.native="showPassword = !showPassword" />
                        </div>

                        <small v-if="isPasswordInvalid" class="invalid-text">
                            {{ $t("incorrect_password") | capitalize }}
                        </small>
                    </nvx-section>
                </nvx-list>
            </template>

            <template v-slot:buttons>
                <button class="sdc-button" :disabled="!(isNewEmailValid && isPasswordCorrect)" block
                    @click="submitNewEmail()">
                    {{ $t("Save") }}
                </button>
            </template>

        </nvx-button-view>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Chevron from '../svg/chevron.vue';
import Eyes from "../svg/eyes.vue";
import NvxSection from "@/components/ui/NvxSection.vue";
import NvxList from "@/components/ui/NvxList.vue";
import NvxButtonView from "@/components/ui/NvxButtonView.vue";

export default {
    components: {
        Chevron,
        Eyes,
        NvxSection,
        NvxList,
        NvxButtonView,
    },
    name: "AccountEmailUpdate",

    data() {
        return {
            newEmail: "",
            isNewEmailValidating: null,
            password: "",
            remaining: 0,
            showPassword: false,
            isNewEmailInvalid: false,
            isPasswordInvalid: false,
        };
    },

    computed: {
        ...mapGetters("users", ["getUserName"]),
        isNewEmailValid() {
            var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            return this.newEmail && this.newEmail !== this.getUserName && validRegex.test(this.newEmail);
        },
        isPasswordCorrect() {
            return this.password.trim() !== "";
        }
    },

    methods: {
        ...mapActions("users", ["sendEmailConfirmation", "fetchUserData"]),
        async submitNewEmail() {
            this.verifyAuth(this.newEmail, this.password)
        },
        verifyAuth(userName, password) {
            window.location.href = `/verify_auth?email=${userName}&password=${password}&return_path=account_email_update_complete`;
        },
        checkNewEmail() {
            var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            this.isNewEmailInvalid = !(this.newEmail && this.newEmail !== this.getUserName && validRegex.test(this.newEmail));
        },
        checkPassword() {
            this.isPasswordInvalid = this.password.trim() === "";
        },
        updateFromRoute() {
            let parameters = this.$route.query;
            if (parameters["email"]) {
                this.isNewEmailValidating = parameters["email"].replace(" ", "+");
                this.newEmail = this.isNewEmailValidating;
            }
            if (parameters["validated"]) {
                this.isPasswordInvalid = parameters["validated"] !== "true";
            }
        }
    },

    async created() {
        this.updateFromRoute();
    },

    async beforeRouteUpdate(to, from, next) {
        this.updateFromRoute();
        next();
    },

    async mounted() {
        this.fetchUserData();
        window.zE("webWidget", "hide");
    }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";
@import "@/assets/scss/appmenus/native.scss";
</style>